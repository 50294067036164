import "../../styles/contact.css";
import ContactFormComponent from "../../components/ContactForm";
import arobaseRose from "../../assets/icons/arobase_rose.png";
import telephone from "../../assets/icons/telephone.png";
import linkedin from "../../assets/icons/linkedin.png";
import instagram from "../../assets/icons/instagram.png";
import tiktok from "../../assets/icons/tiktok.png";
import linkedinHover from "../../assets/icons/linkedin_hover.png";
import instagramHover from "../../assets/icons/instagram_hover.png";
import tiktokHover from "../../assets/icons/tiktok_hover.png";
import { Helmet } from "react-helmet-async";
import { useState } from "react";

const Contact = () => {
  const [hoveredLinkedin, setHoveredLinkedin] = useState();
  const [hoveredInstagram, setHoveredInstagram] = useState();
  const [hoveredTiktok, setHoveredTiktok] = useState();

  const handleMouseOverLinkedin = () => {
    setHoveredLinkedin(true);
  };

  const handleMouseOverInstagram = () => {
    setHoveredInstagram(true);
  };

  const handleMouseOverTiktok = () => {
    setHoveredTiktok(true);
  };

  return (
    <div className="main-content contact color-pink">
      <Helmet>
        <title>Iris Web - Contact</title>
        <meta name="description" content="Un projet en cours ? Contacte nous pour en parler." />
      </Helmet>
      <div className="contact-content">
        <ContactFormComponent />
        <div className="column">
          <div className="contact-informations">
            <div className="contact-informations-header">
              <p>Contact</p>
            </div>
            <div className="contact-informations-content">
              <p>
                Besoin d'aide ou juste envie de discuter de ton prochain projet
                ? On est là pour toi ! Contacte-nous et on se fera un plaisir de
                te répondre dans les plus brefs délais.
              </p>
              <div className="inline">
                <img
                  src={arobaseRose}
                  alt="arobase"
                  className="contact-informations-icons"
                />
                <a href="mailto:hello@iris-web.fr">hello@iris-web.fr</a>
              </div>
              <div className="inline">
                <img
                  src={telephone}
                  alt="telephone"
                  className="contact-informations-icons"
                />
                <a href="tel:+33679883939">06.79.88.39.39</a>
              </div>
              <div className="inline contact-informations-socials">
                <a
                  href="https://www.linkedin.com/company/iris-web-agence-web/"
                  target="_blank"
                  rel="noreferrer"
                  onMouseOver={handleMouseOverLinkedin}
                  onMouseOut={() => setHoveredLinkedin(false)}
                >
                  <img
                    src={hoveredLinkedin ? linkedinHover : linkedin}
                    alt="logo LinkedIn"
                  />
                </a>
                <a
                  href="https://www.instagram.com/iris_web_/"
                  target="_blank"
                  rel="noreferrer"
                  onMouseOver={handleMouseOverInstagram}
                  onMouseOut={() => setHoveredInstagram(false)}
                >
                  <img
                    src={hoveredInstagram ? instagramHover : instagram}
                    alt="logo Instagram"
                  />
                </a>
                <a
                  href="https://www.tiktok.com/@irisweb_"
                  target="_blank"
                  rel="noreferrer"
                  onMouseOver={handleMouseOverTiktok}
                  onMouseOut={() => setHoveredTiktok(false)}
                >
                  <img
                    src={hoveredTiktok ? tiktokHover : tiktok}
                    alt="logo TikTok"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="contact-informations">
            <div className="contact-informations-header">
              <p>Et si on prenait contact ?</p>
            </div>
            <div className="contact-informations-content">
              <a
                href="https://calendly.com/iris-web/prise-de-contact"
                target="_blank"
                rel="noreferrer"
              >
                Je prends rendez-vous !
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
