import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import DraggablePopupComponent from "../../components/DraggablePopup";
import rond from "../../assets/icons/rond_service.png";
import rond_lila from "../../assets/icons/rond_service_lila.png";
import "../../styles/services.css";
import CarouselServicesResponsive from "../../components/CarouselServicesResponsive";
import { usePopupContext } from "../../context/PopupContext";
import { Helmet } from "react-helmet-async";

const Services = () => {
  const { openPopupIndex, setOpenPopupIndex } = usePopupContext();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1100);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const popupsCreationRefonteSiteWeb = [
    {
      title: "Marre de ton site web qui ne te ressemble plus ?",
      text: "<p>Il est temps de lui donner un coup de jeune et de le rendre plus attractif que jamais !</p> <p>Pas encore de site web ? On te guide de A à Z dans la création d'une vitrine digitale qui se démarque ! </p>",
      top: "10%",
      left: "10%",
    },
    {
      title: "Avec nous, ton site web sera :",
      text: "<ul><li>Attrayant et percutant : Un design qui capte l’attention et incite à la découverte.</li><li>Performant : Optimisé pour convertir tes visiteurs en clients.</li><li>Intuitif et accessible : Une interface simple et facile à utiliser pour une navigation fluide.</li></ul>",
      top: "45%",
      left: "20%",
    },
    {
      title: "On s'occupe de tout :",
      text: "<ul><li>Création sur-mesure ou relooking total (site vitrine & e-commerce).</li><li>Hébergement et sécurité au top.</li><li>Adapté au mobile et à la tablette.</li><li>Gestion facile, même pour les novices.</li></ul>",
      top: "10%",
      left: "55%",
    },
  ];

  const popupsMaintenanceWeb = [
    {
      title: "Pas le temps de t’occuper de la maintenance de ton site web ?",
      text: "<p>On s'en charge pour toi !</p><p>Avec notre service de maintenance, ton site web sera toujours :<ul><li>Performant et sécurisé : Mises à jour régulières, corrections de bugs et surveillance constante.</li><li>Attrayant et à jour : Ajout de nouvelles pages, optimisation du design et des images</li><li>Facile à utiliser : Interface intuitive et accessible pour une navigation fluide.</li></ul>",
      top: "10%",
      left: "10%",
    },
    {
      title: "On s'occupe de tout !",
      text: "<ul><li>Ajout de nouveaux articles à ton blog.</li><li>Gestion de ton hébergement et les adresses emails.</li><li>Protection contre les virus et les attaques malveillantes.</li></ul>",
      top: "45%",
      left: "55%",
    },
  ];

  const popupsCreationRefonteGraphique = [
    {
      title: "Ta communication manque de cohérence et d'impact ?",
      text: "<p>Il est temps de la dynamiser avec une charte graphique professionnelle !</p><p>Avec notre expertise, ta charte graphique sera :</p><ul><li>Harmonieuse et distinctive : Un design qui reflète fidèlement à ton ADN et tes valeurs.</li><li>Inoubliable et impactante : Une identité visuelle qui marque les esprits et te démarque de tes concurrents.</li><li>Adaptée à tous tes usages : Un logo, des couleurs, une typographie... utilisables sur tous tes supports de communication, du web à l’imprimé.</li></ul>",
      top: "10%",
      left: "55%",
    },
    {
      title: "Nous prenons en charge l'intégralité du processus :",
      text: "<ul><li>Création sur-mesure ou refonte complète de ta charte graphique.</li><li>Définition de ton identité visuelle (logo, couleurs, typographie).</li><li>Déclinaison de ta charte graphique sur tous tes supports.</li><li>Guide d'utilisation pour une application cohérente de ta charte graphique.</li></ul>",
      top: "45%",
      left: "10%",
    },
  ];

  const popupsCreationPrint = [
    {
      title:
        "Tu veux créer des prints qui captent l'attention et marquent durablement ?",
      text: "<p> Donne vie à tes idées avec des designs percutants.</p><p>Avec notre savoir-faire, tes supports print seront :</p><ul><li>Visuellement attrayants et impactants : Des designs accrocheurs qui captivent l'attention et suscitent l'intérêt.</li><li>Parfaitement adaptés à ton message : Une communication claire et concise qui transmet efficacement tes propos.</li><li>Déclinables sur plusieurs supports : Brochures, flyers, affiches, dépliants et plus encore, pour une identité visuelle cohérente.</li></ul>",
      top: "10%",
      left: "10%",
    },
    {
      title: "On s'occupe de tout pour créer tes supports print :",
      text: "<ul><li>Analyse détaillée de tes besoins et objectifs.</li><li>Conception sur mesure en accord avec ta charte graphique.</li><li>Création de supports de haute qualité, avec une attention particulière portée aux détails.</li><li>Dédiés à t’offrir un résultat qui te convient parfaitement.</li></ul>",
      top: "45%",
      left: "55%",
    },
  ];

  const popupsReseauxSociaux = [
    {
      title:
        "Envie de dynamiser ta présence en ligne et faire résonner ta marque ?",
      text: "<p>Propulse-la avec des posts percutants.</p><p>Fais équipe avec nos experts en réseaux sociaux pour :</p><ul><li>Développer une stratégie adaptée à tes objectifs de marque.</li><li>Créer du contenu engageant qui touche ton public cible.</li><li>Gérer ta présence sur différentes réseaux sociaux.</li><li>Suivre et analyser tes performances pour mesurer le retour sur investissement.</li></ul>",
      top: "10%",
      left: "50%",
    },
    {
      title: "Grâce à notre expertise, tu pourras :",
      text: "<ul><li>Accroître la notoriété et la visibilité de ta marque.</li><li>Générer du trafic vers ton site web et booster tes ventes.</li><li>Renforcer tes relations client et fidéliser ton audience.</li></ul>",
      top: "45%",
      left: "10%",
    },
  ];

  const popupsCreationNewsletter = [
    {
      title: "Prêt à booster tes résultats et à tisser des liens ?",
      text: "<p>Lance-toi dans la newsletter dès maintenant pour propulser ton entreprise vers de nouveaux sommets ! </p><p>Fais équipe avec nos experts en marketing par newsletter pour :</p><ul><li>Concevoir des campagnes d'emailing qui captivent tes abonnés.</li><li>Segmenter ton audience pour des messages ciblés et des expériences personnalisées.</li><li>Suivre et analyser les performances de tes newsletters pour mesurer le retour sur investissement.</li></ul>",
      top: "10%",
      left: "10%",
    },
    {
      title: "Grâce à notre expertise, tu peux :",
      text: "<ul><li>Fidéliser tes abonnés et renforcer tes relations client.</li><li>Générer du trafic vers ton site web et booster tes ventes.</lI><li>Promouvoir de nouveaux produits et services auprès d'un public réceptif.</li></ul>",
      top: "45%",
      left: "55%",
    },
  ];

  const handlePopupOpen = (index) => {
    setOpenPopupIndex(index);
    if (openPopupIndex === index) {
      setOpenPopupIndex(null);
    }
  };

  return (
    <div className="main-content services">
      <Helmet>
        <title>Iris Web - Services</title>
        <meta
          name="description"
          content="Découvre nos services, de la conception de sites web, communication digitale, en passant par divers créations graphiques et l'animation de tes réseaux sociaux'."
        />
      </Helmet>
      {isMobile ? (
        <div className="responsive-footer-menu">
          <CarouselServicesResponsive />
        </div>
      ) : (
        <div className="inline footer-menu">
          <DraggablePopupComponent
            popups={popupsCreationRefonteSiteWeb}
            buttonImage={rond}
            buttonImageOver={rond_lila}
            buttonText="Site web"
            modalIsOpen={openPopupIndex === 0}
            onOpen={() => handlePopupOpen(0)}
          />
          <DraggablePopupComponent
            popups={popupsCreationRefonteGraphique}
            buttonImage={rond}
            buttonImageOver={rond_lila}
            buttonText="Charte graphique"
            modalIsOpen={openPopupIndex === 1}
            onOpen={() => handlePopupOpen(1)}
          />
          <DraggablePopupComponent
            popups={popupsReseauxSociaux}
            buttonImage={rond}
            buttonImageOver={rond_lila}
            buttonText="Réseaux sociaux"
            modalIsOpen={openPopupIndex === 2}
            onOpen={() => handlePopupOpen(2)}
          />
          <DraggablePopupComponent
            popups={popupsCreationNewsletter}
            buttonImage={rond}
            buttonImageOver={rond_lila}
            buttonText="Newsletter"
            modalIsOpen={openPopupIndex === 3}
            onOpen={() => handlePopupOpen(3)}
          />
          <DraggablePopupComponent
            popups={popupsCreationPrint}
            buttonImage={rond}
            buttonImageOver={rond_lila}
            buttonText="Supports publicitaires"
            modalIsOpen={openPopupIndex === 4}
            onOpen={() => handlePopupOpen(4)}
          />
          <DraggablePopupComponent
            popups={popupsMaintenanceWeb}
            buttonImage={rond}
            buttonImageOver={rond_lila}
            buttonText="Maintenance web"
            modalIsOpen={openPopupIndex === 5}
            onOpen={() => handlePopupOpen(5)}
          />
        </div>
      )}
    </div>
  );
};

export default Services;
