import React, { useState } from "react";
import { Link } from "react-router-dom";
import { usePopupContext } from "../../../context/PopupContext";

const SolarSystem = ({ toggleIsOpen }) => {
  const { setOpenPopupIndex } = usePopupContext();
  const [serviceText, setServiceText] = useState("Services");

  const handleClick = (index) => {
    setOpenPopupIndex(index);
    toggleIsOpen();
  };

  const handleMouseEnter = (text) => {
    setServiceText(text);
  };

  const handleMouseLeave = () => {
    setServiceText("Services");
  };

  return (
    <div>
      <div className="container">
        <div className="sun"></div>
        <div className="venus-orbit">
          <Link to="/services" onClick={() => handleClick(0)}>
            <div
              className="venus"
              onMouseEnter={() => handleMouseEnter("Création refonte site web")}
              onMouseLeave={handleMouseLeave}
            ></div>
          </Link>
        </div>
        <div className="earths-orbit">
          <Link to="/services" onClick={() => handleClick(1)}>
            <div
              className="earth"
              onMouseEnter={() => handleMouseEnter("Maintenance web")}
              onMouseLeave={handleMouseLeave}
            ></div>
          </Link>
        </div>
        <div className="mars-orbit">
          <Link to="/services" onClick={() => handleClick(2)}>
            <div
              className="mars"
              onMouseEnter={() =>
                handleMouseEnter("Création refonte graphique")
              }
              onMouseLeave={handleMouseLeave}
            ></div>
          </Link>
        </div>
        <div className="jupiters-orbit">
          <Link to="/services" onClick={() => handleClick(3)}>
            <div
              className="jupiter"
              onMouseEnter={() => handleMouseEnter("Création print")}
              onMouseLeave={handleMouseLeave}
            ></div>
          </Link>
        </div>
        <div className="saturns-orbit">
          <Link to="/services" onClick={() => handleClick(4)}>
            <div
              className="saturn"
              onMouseEnter={() => handleMouseEnter("Réseaux sociaux")}
              onMouseLeave={handleMouseLeave}
            ></div>
          </Link>
        </div>
        <div className="uranus-orbit">
          <Link to="/services" onClick={() => handleClick(5)}>
            <div
              className="uranus"
              onMouseEnter={() => handleMouseEnter("Création newsletter")}
              onMouseLeave={handleMouseLeave}
            ></div>
          </Link>
        </div>
      </div>
      <p className={`service-text ${serviceText !== "Services" ? 'service-text-open' : ''}`}>{serviceText}</p>
    </div>
  );
};

export default SolarSystem;
