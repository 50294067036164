import React, { createContext, useState, useCallback } from 'react';

// Créer un contexte
export const AccordionContext = createContext();

export const AccordionProvider = ({ children }) => {
  const [clickCount, setClickCount] = useState(0);
  const [totalClicks, setTotalClicks] = useState(3);

  const popupInFront = useCallback(() => {
    setClickCount((prevCount) => prevCount + 1);
    setTotalClicks((prevTotal) => prevTotal + 1);
  }, []);

  return (
    <AccordionContext.Provider value={{ clickCount, totalClicks, popupInFront }}>
      {children}
    </AccordionContext.Provider>
  );
};
