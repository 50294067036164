import React, { createContext, useState, useContext } from 'react';

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
    const [openPopupIndex, setOpenPopupIndex] = useState(null);

    return (
        <PopupContext.Provider value={{ openPopupIndex, setOpenPopupIndex }}>
            {children}
        </PopupContext.Provider>
    );
};

export const usePopupContext = () => useContext(PopupContext);
