const CarouselAboutUsItemComponent = ({name, avatar, job, jobDescription}) => {
    return (
        <div className="carousel-item color-pink">
            <div className="carousel-item-header">
                <h2>{name}</h2>
            </div>
            <div className="carousel-item-body">
                <img src={avatar} alt={`Avatar de ` + name} className="carousel-item-image"/>
                <p className="carousel-item-job">{job}</p>
                <p className="carousel-item-job-description">{jobDescription}</p>
            </div>
        </div>
    )
}

export default CarouselAboutUsItemComponent