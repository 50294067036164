import React, { useState, useEffect } from "react";
import DraggablePopupComponent from "../../components/DraggablePopup";
import dossier from "../../assets/icons/dossier.png";
import dossier_lila from "../../assets/icons/hover/portfolio_hover.png";
import devis from "../../assets/icons/devis_personnalise.png";
import devis_lila from "../../assets/icons/hover/devis_personnalise_hover.png";

import "../../styles/tarifs_devis.css";
import ResponsiveModalComponent from "../../components/ResponsiveModal";
import { Helmet } from "react-helmet-async";
import ReactModal from "react-modal-resizable-draggable";

const PricesAndQuotations = () => {
  const [openPopupIndex, setOpenPopupIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1100);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const popupsCreationNewsletter = [
    {
      title: "Prêt à dynamiser ta communication emailing ?",
      text: "<p>Notre forfait newsletter à partir de 90 € HT/mois comprend :</p><ul><li>Création de newsletters sur-mesure.</li><li>Design attrayant et professionnel.</li><li>Intégration des articles.</li><li>Envol personnalisé.</li><li>Suivi des performances détaillé.</ul><p>Bénéficie de notre savoir-faire pour atteindre tes objectifs.</p>",
      top: "50%",
      left: "50%",
    },
  ];

  const popupsMaintenanceWeb = [
    {
      title: "Optimise les performances de ton site internet !",
      text: "<p>Pour seulement 55 € HT/mois, nous prenons soin de ton site web :</p><ul><li>Mises à jour régulières.</li><li>Corrections de bugs.</li><li>Optimisation pour les moteurs de recherche.</li><li>Surveillance des performances.</li></ul><p>Simplifie-toi la vie et assure la pérennité de ton site.</p>",
      top: "50%",
      left: "50%",
    },
  ];

  const popupsReseauxSociaux = [
    {
      title: "Prêt à faire décoller tes réseaux sociaux ?",
      text: "<p>Notre formule tout-en-un à partir de 320 € HT/mois inclut :</p><ul><li>Création de contenus engageants (4 posts/mois).</li><li>Une sélection de hashtags pertinents pour toucher ta cible.</li><li>Planification stratégique pour toucher un maximum d'internautes.</li><li>Publication régulière pour maintenir l'intérêt de ta communauté.</li><li>Suivi de tes statistiques pour mesurer ton impact.</li></ul><p>Délégue la gestion de tes réseaux et concentre-toi sur l'essentiel.</p>",
      top: "50%",
      left: "50%",
    },
  ];

  const popupsCreationRefonteGraphique = [
    {
      title:
        "Donne vie à ta marque avec une charte graphique professionnelle !",
      text: "<p>Dès 1 720 € HT, bénéficie d'un service complet :</p><ul><li>Analyse approfondie de ton activité et de ta cible pour créer une identité unique.</li><li>Conception d'un logo qui te ressemble et te représente.</li><li>Sélection méticuleuse des couleurs et des typographies pour une harmonie parfaite.</li><li>Création des éléments graphiques nécessaires pour une communication cohérente.</li><li>Livraison d'un guide graphique pour garantir une utilisation optimale de ton identité visuelle.</li></ul><p>Une charte graphique unique pour te démarquer de la concurrence.</p>",
      top: "50%",
      left: "50%",
    },
  ];

  const popupsTemplatesReseauxSociaux = [
    {
      title: "Des visuels réseaux sociaux uniques pour ta marque !",
      text: "<p>Dès 1 010 € HT, bénéficiez de templates créés sur-mesure :</p><ul><li>Analyse approfondie de ta,p cible et de tes concurrents.</li><li>Création de templates adaptés à chaque format et à chaque plateforme.</li><li>Des visuels percutants pour maximiser ton portée et ton engagement.</li></ul><p>Gagne du temps et optimise ta communication grâce à des visuels professionnels.</p>",
      top: "50%",
      left: "50%",
    },
  ];

  const popupsSiteVitrine = [
    {
      title: "Un site web professionnel à ton image !",
      text: "<p>Nous créons ton site internet de A à Z, dès 3 300 € HT :</p><ul><li>Analyse appronfondie de ton marché et de concurrence.</li><li>Création d'une identité visuelle forte et mémorable.</li><li>Développement technique sur-mesure.</li><li>Optimisation SEO pour un meilleur référencement.</li></ul><p>Un projet clé en main pour te permettre de te concentrer sur ton activité.</p>",
      top: "50%",
      left: "50%",
    },
  ];

  const popupsSupportPrints = [
    {
      title: "Booste ta visibilité avec des supports print sur-mesure !",
      text: "<p>Nous prenons en charge la création de tous tes supports, dès 1 470 € HT :</p><ul><li>Flyer accordéon : À partir de 630 € HT, bénéficie d'un outil de communication complet pour présenter tes produits ou services de manière détaillée.</li><li>Carte de visite : Pour seulement 140 € HT, laisse une impression durable avec une carte de visite professionnelle.</li><li>Affiche : Attire l'attention sur tes événements ou promotions pour 175 € HT seulement.</li><li>Kakémono : Mets en valeur ton entreprise lors de tes événements à partir de 350 € HT.</li><li>Flyer simple recto-verso : Une communication efficace à petit prix, dès 175 € HT.</li></ul><p>Un pack complet pour renforcer ton image de marque.</p>",
      top: "50%",
      left: "50%",
    },
  ];

  const popupsFormationWeb = [
    {
      title: "Deviens autonome sur ton site web !",
      text: "<p>Dès 110 € HT, bénéficie d'une session personnalisée avec un développeur :</p><ul><li>Comprendre le fonctionnement de ton site.</li><li>Modifier ton contenu en quelques clics.</li><li>Résoudre les petits soucis sans stress.</li><li>Poser toutes tes questions à un développeur expérimenté.</li><li>Bénéficie d'un guide clair et concis.</li></ul><p>Un investissement rentable pour gagner en efficacité et en indépendance.</p>",
      top: "50%",
      left: "50%",
    },
  ];

  const popupsCreationGraphiqueEvenementielle = [
    {
      title: "Besoin de visuels impactants pour ton prochain événement ?",
      text: "<p>Nous te proposons un service de création graphique personnalisé. Bannière, habillage stand… nous concevons tous les supports dont tu as besoin pour marquer les esprits. Chaque projet est unique et adapté à tes spécificités.</p><p>Contacte-nous pour discuter de ton projet et obtenir un devis personnalisé.</p>",
      top: "50%",
      left: "50%",
    },
  ];

  const popupsSiteEcommerce = [
    {
      title: "Ta boutique en ligne, clé en main !",
      text: "<p>Nous concevons et développons ton site e-commerce en fonction de tes produits, de ton image de marque et de tes objectifs commerciaux. Un site performant, sécurisé et optimisé pour le référencement, pour t’assurer des ventes en ligne réussies.</p><p>Contacte-nous pour discuter de ton projet et obtenir un devis personnalisé.</p>",
      top: "50%",
      left: "50%",
    },
  ];

  const popupsMontageVideo = [
    {
      title: "Tu as une idée, un projet vidéo ?",
      text: "<p>Nous la transformons en réalité ! Grâce à notre expertise en montage vidéo, nous créons des vidéos uniques et impactantes, adaptées à tes besoins et à ta cible. Que ce soit pour un film d'entreprise ou un montage évènementiel, nous te proposons un service créatif et personnalisé.</p><p>Contacte-nous pour discuter de ton projet et obtenir un devis personnalisé.</p>",
      top: "50%",
      left: "50%",
    },
  ];

  const popupsStarterPack = [
    {
      title:
        "Lance-toi sur le web en un clin d'œil avec notre pack à prix abordable !",
      text: "<p>Dès 1 400 € HT, bénéficiez d'un site web one-page clé en main :</p><ul><li>Conception d'une maquette graphique.</li><li>Intégration rapide sur la plateforme Wix, intuitive et facile à utiliser.</li><li>Livraison d'un site prêt à être mis en ligne.</li></ul><p>Simple, efficace et rapide. Idéal pour les petites entreprises et les indépendants.</p>",
      top: "50%",
      left: "50%",
    },
  ];

  const popupsStrategieCommunication = [
    {
      title:
        "Atteins tes objectifs grâce à une stratégie digitale performante !",
      text: "<p>Dès 2 400 € HT, booste ta communication :</p><ul><li>Audit approfondi de ta présence en ligne.</li><li>Analyse de la concurrence pour te démarquer et gagner en visibilité.</li><li>Veille stratégique pour rester à l'affût des dernières tendances.</li><li>Création d'une charte éditoriale pour une communication cohérente et efficace.</li><li>Élaboration d'un planning éditorial pour organiser ton contenu et optimiser ton temps.</li></ul><p>Une stratégie personnalisée pour une communication impactante et réussie.</p>",
      top: "50%",
      left: "50%",
    },
  ];

  const headerInformationsTarifs = [
    {
      title: "Informations",
      text: "Nos tarifs débutent à partir de prix de base et sont ajustés selon les besoins spécifiques de chaque client, avec la possibilité de paiements en plusieurs fois.",
      top: "20%",
      left: "55%",
      contentType: "informationsTarifs",
    },
  ];

  const handlePopupOpen = (index) => {
    setOpenPopupIndex(index);
    if (openPopupIndex === index) {
      setOpenPopupIndex(null);
    }

    if (isMobile) {
      const mainContent = document.querySelector(".main-content");
      mainContent.classList.add("hide");
    }
  };

  return (
    <div className="main-content tarifs-devis">
      <Helmet>
        <title>Iris Web - Tarifs et devis</title>
        <meta
          name="description"
          content="Voici nos différents tarifs pour tous nos services. Pour toute demande personnalisée contacte nous directement !"
        />
      </Helmet>
      {isMobile ? (
        <div className="tarifs-devis-responsive">
          <div className="tarifs-devis-informations">
            <h2>Informations</h2>
            <p>
              Nos tarifs débutent à partir de prix de base et sont ajustés selon
              les besoins spécifiques de chaque client, avec la possibilité de
              paiements en plusieurs fois.
            </p>
          </div>
          <div className="inline">
            <div className="icons-column">
              <ResponsiveModalComponent
                modals={popupsReseauxSociaux}
                buttonImage={dossier}
                buttonText="Animation réseaux sociaux"
                modalIsOpen={openPopupIndex === 2}
                onOpen={() => handlePopupOpen(2)}
              />
              <ResponsiveModalComponent
                modals={popupsCreationRefonteGraphique}
                buttonImage={dossier}
                buttonText="Charte graphique"
                modalIsOpen={openPopupIndex === 3}
                onOpen={() => handlePopupOpen(3)}
              />
              <ResponsiveModalComponent
                modals={popupsCreationGraphiqueEvenementielle}
                buttonImage={devis}
                buttonText="Design évenementiel"
                modalIsOpen={openPopupIndex === 9}
                onOpen={() => handlePopupOpen(9)}
              />
              <ResponsiveModalComponent
                modals={popupsFormationWeb}
                buttonImage={dossier}
                buttonText="Formation web"
                modalIsOpen={openPopupIndex === 8}
                onOpen={() => handlePopupOpen(8)}
              />
              <ResponsiveModalComponent
                modals={popupsMaintenanceWeb}
                buttonImage={dossier}
                buttonText="Maintenance Web"
                modalIsOpen={openPopupIndex === 1}
                onOpen={() => handlePopupOpen(1)}
              />
              <ResponsiveModalComponent
                modals={popupsMontageVideo}
                buttonImage={devis}
                buttonText="Montage vidéo"
                modalIsOpen={openPopupIndex === 11}
                onOpen={() => handlePopupOpen(11)}
              />

              <ResponsiveModalComponent
                modals={popupsCreationNewsletter}
                buttonImage={dossier}
                buttonText="Newsletter"
                modalIsOpen={openPopupIndex === 0}
                onOpen={() => handlePopupOpen(0)}
              />
            </div>
            <div className="icons-column">
              <ResponsiveModalComponent
                modals={popupsStarterPack}
                buttonImage={dossier}
                buttonText="Site à petit prix"
                modalIsOpen={openPopupIndex === 12}
                onOpen={() => handlePopupOpen(12)}
              />
              <ResponsiveModalComponent
                modals={popupsSiteEcommerce}
                buttonImage={devis}
                buttonText="Site E-commerce"
                modalIsOpen={openPopupIndex === 10}
                onOpen={() => handlePopupOpen(10)}
              />
              <ResponsiveModalComponent
                modals={popupsSiteVitrine}
                buttonImage={dossier}
                buttonText="Site vitrine"
                modalIsOpen={openPopupIndex === 6}
                onOpen={() => handlePopupOpen(6)}
              />
              <ResponsiveModalComponent
                modals={popupsStrategieCommunication}
                buttonImage={dossier}
                buttonText="Stratégie de Communication"
                modalIsOpen={openPopupIndex === 13}
                onOpen={() => handlePopupOpen(13)}
              />
              <ResponsiveModalComponent
                modals={popupsSupportPrints}
                buttonImage={dossier}
                buttonText="Supports publicitaires"
                modalIsOpen={openPopupIndex === 7}
                onOpen={() => handlePopupOpen(7)}
              />
              <ResponsiveModalComponent
                modals={popupsTemplatesReseauxSociaux}
                buttonImage={dossier}
                buttonText="Templates réseaux sociaux"
                modalIsOpen={openPopupIndex === 4}
                onOpen={() => handlePopupOpen(4)}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <DraggablePopupComponent
            popups={headerInformationsTarifs}
            modalIsOpen={true}
          />
          <div className="inline space-between">
            <div className="inline">
              <div>
                <DraggablePopupComponent
                  popups={popupsReseauxSociaux}
                  buttonImage={dossier}
                  buttonImageOver={dossier_lila}
                  buttonText="Animation réseaux sociaux"
                  modalIsOpen={openPopupIndex === 2}
                  onOpen={() => handlePopupOpen(2)}
                />
                <DraggablePopupComponent
                  popups={popupsCreationRefonteGraphique}
                  buttonImage={dossier}
                  buttonImageOver={dossier_lila}
                  buttonText="Charte graphique"
                  modalIsOpen={openPopupIndex === 3}
                  onOpen={() => handlePopupOpen(3)}
                />

                <DraggablePopupComponent
                  popups={popupsFormationWeb}
                  buttonImage={dossier}
                  buttonImageOver={dossier_lila}
                  buttonText="Formation web"
                  modalIsOpen={openPopupIndex === 8}
                  onOpen={() => handlePopupOpen(8)}
                />
                <DraggablePopupComponent
                  popups={popupsMaintenanceWeb}
                  buttonImage={dossier}
                  buttonImageOver={dossier_lila}
                  buttonText="Maintenance Web"
                  modalIsOpen={openPopupIndex === 1}
                  onOpen={() => handlePopupOpen(1)}
                />
              </div>
              <div>
                <DraggablePopupComponent
                  popups={popupsCreationNewsletter}
                  buttonImage={dossier}
                  buttonImageOver={dossier_lila}
                  buttonText="Newsletter"
                  modalIsOpen={openPopupIndex === 0}
                  onOpen={() => handlePopupOpen(0)}
                />
                <DraggablePopupComponent
                  popups={popupsStarterPack}
                  buttonImage={dossier}
                  buttonImageOver={dossier_lila}
                  buttonText="Site à petit prix"
                  modalIsOpen={openPopupIndex === 12}
                  onOpen={() => handlePopupOpen(12)}
                />
                <DraggablePopupComponent
                  popups={popupsSiteVitrine}
                  buttonImage={dossier}
                  buttonImageOver={dossier_lila}
                  buttonText="Site vitrine"
                  modalIsOpen={openPopupIndex === 6}
                  onOpen={() => handlePopupOpen(6)}
                />
                <DraggablePopupComponent
                  popups={popupsStrategieCommunication}
                  buttonImage={dossier}
                  buttonImageOver={dossier_lila}
                  buttonText="Stratégie de Communication"
                  modalIsOpen={openPopupIndex === 13}
                  onOpen={() => handlePopupOpen(13)}
                />
              </div>
              <div>
                <DraggablePopupComponent
                  popups={popupsTemplatesReseauxSociaux}
                  buttonImage={dossier}
                  buttonImageOver={dossier_lila}
                  buttonText="Templates réseaux sociaux"
                  modalIsOpen={openPopupIndex === 4}
                  onOpen={() => handlePopupOpen(4)}
                />

                <DraggablePopupComponent
                  popups={popupsSupportPrints}
                  buttonImage={dossier}
                  buttonImageOver={dossier_lila}
                  buttonText="Supports publicitaires"
                  modalIsOpen={openPopupIndex === 7}
                  onOpen={() => handlePopupOpen(7)}
                />
              </div>
            </div>
            <div className="inline">
              <div className="align-self-end">
                <DraggablePopupComponent
                  popups={popupsCreationGraphiqueEvenementielle}
                  buttonImage={devis}
                  buttonImageOver={devis_lila}
                  buttonText="Design évenementiel"
                  modalIsOpen={openPopupIndex === 9}
                  onOpen={() => handlePopupOpen(9)}
                />
                <DraggablePopupComponent
                  popups={popupsMontageVideo}
                  buttonImage={devis}
                  buttonImageOver={devis_lila}
                  buttonText="Montage vidéo"
                  modalIsOpen={openPopupIndex === 11}
                  onOpen={() => handlePopupOpen(11)}
                />
                <DraggablePopupComponent
                  popups={popupsSiteEcommerce}
                  buttonImage={devis}
                  buttonImageOver={devis_lila}
                  buttonText="Site E-commerce"
                  modalIsOpen={openPopupIndex === 10}
                  onOpen={() => handlePopupOpen(10)}
                />
              </div>
            </div>
          </div>
          {/* <ReactModal
            initWidth={600}
            initHeight={200}
            top="15%"
            left="70%"
            disableResize={true}
            className="draggable-popup"
          >
            <div>
              <div className="popup-header">
                <h2>Informations</h2>
              </div>
              <div className="popup-text">
                <p>
                  Nos tarifs débutent à partir de prix de base et sont ajustés
                  selon les besoins spécifiques de chaque client, avec la
                  possibilité de paiements en plusieurs fois.
                </p>
              </div>
            </div>
          </ReactModal> */}
        </>
      )}
    </div>
  );
};

export default PricesAndQuotations;
