import "../../styles/newsletter.css";
import { useState, useEffect, useRef } from "react";
import emailjs from "emailjs-com";
import newsletterLogo from "../../assets/icons/newsletter.png";
import chevron_right from "../../assets/icons/chevron_droite.png";

const NewsletterComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [initialClick, setInitialClick] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const serviceId = process.env.REACT_APP_EMAILJS_SERVICEID;
  const templateIdNewsletter =
    process.env.REACT_APP_EMAILJS_TEMPLATEIDNEWSLETTER;
  const userId = process.env.REACT_APP_EMAILJS_USERID;

  const popupRef = useRef(null);

  const openNewsletter = () => {
    setInitialClick(true);
    setIsOpen(!isOpen);
  };

  const handleSubscribeClick = (event) => {
    event.stopPropagation();
  };

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(serviceId, templateIdNewsletter, e.target, userId)
      .then((res) => {
        console.log(res);
        setIsSubmitted(true);
        resetForm();
      })
      .catch((err) => console.log(err));
  }

  function resetForm() {
    document.getElementById("newsletter-form").reset();
  }

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  const popupClass = initialClick
    ? isOpen
      ? "newsletter-opened"
      : "newsletter-closed"
    : "";

  return (
    <div
      ref={popupRef}
      className={`newsletter-popup color-purple ${popupClass}`}
      onClick={openNewsletter}
    >
      <div className="inline align-items">
        <img
          src={newsletterLogo}
          alt="logo de newsletter"
          className="newsletter-logo"
        />
        <div>
          <h2>NEWSLETTER</h2>
          {!isSubmitted ? (
            <>
              <p>
                Inscris-toi à notre newsletter pour rester au courant de nos
                aventures !
              </p>
              <form
                id="newsletter-form"
                className="inline"
                onClick={handleSubscribeClick}
                onSubmit={sendEmail}
              >
                <>
                  <input
                    type="email"
                    name="emailnewsletter"
                    placeholder="ton@email.fr"
                    required
                  />
                  <button type="submit">
                    <img src={chevron_right} alt="chevron right" />
                  </button>
                </>
              </form>
            </>
          ) : (
            <p className="color-lilac">
              Merci de ton inscription à notre Newsletter !
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsletterComponent;
