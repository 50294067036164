import { Helmet } from "react-helmet-async";
import CarouselAboutUsComponent from "../../components/CarouselAboutUs";
import "../../styles/aboutus.css";

const AboutUs = () => {
  return (
    <div className="main-content about-us">
      <Helmet>
        <title>Iris Web - Qui Sommes Nous ?</title>
        <meta name="description" content="Découvre les talents derrière notre agence créative !" />
      </Helmet>
      <CarouselAboutUsComponent />
    </div>
  );
};

export default AboutUs;
