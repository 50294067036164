import React, { useState, useEffect } from 'react';

const Loading = () => {
    const [percentage, setPercentage] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage((prev) => {
        if (prev < 100) {
          return prev + 1;
        } else {
          clearInterval(interval);
          return prev;
        }
      });
    }, 0.5);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="loading-component">
        <div className="loading-bar">
            <div className="loading-bar-fill" style={{ width: `${percentage}%` }}></div>
        </div>
        <p>{percentage}%</p>
    </div>
  )
};

export default Loading;