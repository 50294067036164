import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Services from "./pages/Services";
import LandingPage from "./pages/LandingPage";
import Legal from "./pages/Legal/Index";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import PricesAndQuotations from "./pages/PricesAndQuotations";
import Portfolio from "./pages/Portfolio";
// import Blog from './pages/Blog';

import Page404 from "./pages/404";
import ParticlesComponent from "./components/ParticlesBackground";
import Navigation from "./components/Navigation";
import Loading from "./components/Loading";
import "./styles/common.css";

const App = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const isLandingPage = location.pathname === "/";

  useEffect(() => {
    // Simule un délai pour le chargement des ressources initiales
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); // Attendre 0.5sec

    return () => clearTimeout(timer); // Nettoyer le timer si le composant est démonté
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {!isLandingPage && <Navigation />}
      {/* <ParticlesComponent id="particles" /> */} 
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/legal" element={<Legal />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/prices" element={<PricesAndQuotations />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/portfolio" element={<Portfolio />} />
        {/* <Route exact path="/blog" element={<Blog />} /> */}
        <Route path="*" element={<Page404 />} />
      </Routes>
    </>
  );
};

export default App;
