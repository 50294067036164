import { useEffect, useState } from "react";
import { useNavigationContext } from "../../context/NavigationContext"; // Import du contexte
import menuNavFerme from "../../assets/icons/menu_nav_ferme.png";
import menuNavHover from "../../assets/icons/menu_nav_hover.png";
import menuNavOuvert from "../../assets/icons/menu_nav_ouvert.png";
import SolarSystem from "./SolarSystem";
import NavigationButtonComponent from "./NavigationButton";
import NewsletterComponent from "../Newsletter";
import portfolio from "../../assets/icons/portfolio.png";
import tarifsDevis from "../../assets/icons/tarifs_devis.png";
import quiSommesNous from "../../assets/icons/qui_sommes_nous.png";
import contact from "../../assets/icons/arobase.png";
import mentionsLegales from "../../assets/icons/mentions_legales.png";
import portfolioHover from "../../assets/icons/hover/portfolio_hover.png";
import tarifsDevisHover from "../../assets/icons/hover/tarifs_devis_hover.png";
import quiSommesNousHover from "../../assets/icons/hover/qui_sommes_nous_hover.png";
import contactHover from "../../assets/icons/hover/arobase_hover.png";
import mentionsLegalesHover from "../../assets/icons/hover/mentions_legales_hover.png";
import logo from "../../assets/icons/logo.png";

import "../../styles/navigation.css";

const Navigation = () => {
  const { isOpen, setIsOpen } = useNavigationContext(); // Utilisation du contexte
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1100);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Ajouter ou retirer la classe no-scroll à #root avec la hauteur de .menu.open
  useEffect(() => {
    const rootElement = document.getElementById('root');
    const menuElement = document.querySelector('.menu.open');

    if (isMobile && isOpen && menuElement) {
      // Définir la hauteur de #root égale à celle du menu ouvert
      const menuHeight = menuElement.offsetHeight;
      rootElement.style.height = `${menuHeight}px`;
      rootElement.classList.add('no-scroll');  // Empêcher le scroll
    } else {
      // Réinitialiser la hauteur de #root et autoriser le scroll
      rootElement.style.height = '';
      rootElement.classList.remove('no-scroll');
    }
  }, [isMobile, isOpen]);

  function toggleNavMenu() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    const servicesElement = document.querySelector(".main-content");
    if (servicesElement) {
      if (isOpen) {
        servicesElement.classList.add("nav-open");
      } else {
        servicesElement.classList.remove("nav-open");
      }
    }
  }, [isOpen]);

  const navigationFooterData = [
    {
      text: "Portfolio",
      image: portfolio,
      imageOver: portfolioHover,
      link: "/portfolio",
    },
    {
      text: "Tarifs / Devis",
      image: tarifsDevis,
      imageOver: tarifsDevisHover,
      link: "/prices",
    },
    {
      text: "Qui sommes-nous ?",
      image: quiSommesNous,
      imageOver: quiSommesNousHover,
      link: "/about-us",
    },
    {
      text: "Contact",
      image: contact,
      imageOver: contactHover,
      link: "/contact",
    },
    {
      text: "Mentions Légales",
      image: mentionsLegales,
      imageOver: mentionsLegalesHover,
      link: "/legal",
    },
  ];

  return (
    <div>
      <header>
        <div className="nav">
          <a href="/">
            <img src={logo} alt="Logo Iris Web" />
          </a>
          <img
            src={
              isHovered && !isOpen
                ? menuNavHover
                : isOpen
                ? menuNavOuvert
                : menuNavFerme
            }
            alt="menu de navigation"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={toggleNavMenu}
          />
        </div>
        {isMobile ? (
          <>
            <div className={`menu ${isOpen && "open"}`}>
              <div className="menu-scroll">
                <a className="menu-item" href="/services">
                  Services
                </a>
                <a className="menu-item" href="/portfolio">
                  Portfolio
                </a>
                <a className="menu-item" href="/prices">
                  Tarifs
                </a>
                <a className="menu-item" href="/about-us">
                  Qui sommes-nous ?
                </a>
                <a className="menu-item" href="/contact">
                  Contact
                </a>
                <a className="menu-item" href="/legal">
                  Mentions légales
                </a>
                <NewsletterComponent />
              </div>
            </div>
          </>
        ) : (
          <div
            className={`navigation-menu ${isOpen ? "open" : ""} ${
              isOpen === false && "closing"
            }`}
          >
            <div className="solar-system-container">
              <div className="solar-system-blank"></div>
              <SolarSystem toggleIsOpen={toggleNavMenu} />
              <div>
                <div className="solar-system-note">
                  Découvre nos services en passant ta souris sur les planètes du
                  système solaire !
                </div>
              </div>
            </div>
            <div className="nav-menu inline">
              {navigationFooterData.map((navigation) => (
                <NavigationButtonComponent
                  key={navigation.text}
                  text={navigation.text}
                  image={navigation.image}
                  imageOver={navigation.imageOver}
                  link={navigation.link}
                />
              ))}
            </div>
            <NewsletterComponent />
          </div>
        )}
      </header>
    </div>
  );
};

export default Navigation;
