import { Helmet } from "react-helmet-async";
import "../../styles/404.css";
const Page404 = () => {
  return (
    <div className="main-content page404 color-lilac">
      <Helmet>
        <title>Iris Web - 404</title>
      </Helmet>
      <h1>Erreur 404</h1>
      <p>
        La page que tu recherches n'existe pas.
        <br />
        Vérifie le lien renseigné ou clique sur le menu pour te rediriger.
      </p>
    </div>
  );
};

export default Page404;
