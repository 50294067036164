import React, { useState } from 'react';
import "../../../styles/navigation.css";

const NavigationButtonComponent = ({text, image, imageOver, link}) => {
    const [hovered, setHovered] = useState(false);

    const handleMouseOver = () => {
        setHovered(true);
    };

    const handleMouseOut = () => {
        setHovered(false);
    };

    return (
        <div className="nav-menu-button">
            <a href={link} 
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}>
                <img src={hovered ? imageOver : image}  
                    alt={text} />
                <p className={`nav-menu-button-text ${hovered ? "color-lilac" : ""}`}>{text}</p>
            </a>
        </div>
    );
};

export default NavigationButtonComponent;