import React, { useState } from "react";
import emailjs from "emailjs-com";

const ContactFormComponent = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const serviceId = process.env.REACT_APP_EMAILJS_SERVICEID;
  const templateId = process.env.REACT_APP_EMAILJS_TEMPLATEID;
  const userId = process.env.REACT_APP_EMAILJS_USERID;

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(serviceId, templateId, e.target, userId)
      .then((res) => {
        console.log(res);
        setShowConfirmation(true); // Afficher le message de confirmation
        resetForm();
      })
      .catch((err) => console.log(err));
  }

  function resetForm() {
    document.getElementById("contact-form").reset();
  }

  return (
    <div className="form-container">
      <form id="contact-form" className="contact-form" onSubmit={sendEmail}>
        <div className="inline">
          <input
            type="text"
            name="name"
            placeholder="Nom Prénom"
            required
          ></input>
          <input
            type="text"
            name="phonenumber"
            placeholder="Numéro de téléphone"
            required
          ></input>
        </div>
        <input
          type="email"
          name="email"
          placeholder="Adresse email"
          required
        ></input>
        <textarea
          name="message"
          placeholder="Parle nous de ton projet ici!"
          required
        ></textarea>
        <button type="submit" className="contact-button">
          Envoyer
        </button>
      </form>
      {showConfirmation && ( // Afficher le message de confirmation si showConfirmation est true
        <div className="confirmation-message color-pink">
          Merci de ton envoi, nous reviendrons vers toi rapidement !
        </div>
      )}
    </div>
  );
};

export default ContactFormComponent;
