import React, { useContext, useEffect, useRef, useState } from "react";
import { AccordionContext } from "../../context/AccordionContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faChevronDown } from "@fortawesome/free-solid-svg-icons";

const AccordionComponent = ({ title, text, accordionClass }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { clickCount, popupInFront } = useContext(AccordionContext);
  const accordionRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen((prev) => {
      if (!prev) {
        popupInFront(); // Execute popupInFront when opening
      }
      return !prev;
    });
  };

  const handleClickOutside = (event) => {
    if (accordionRef.current && !accordionRef.current.contains(event.target)) {
      setIsOpen(false); // Close the accordion if clicked outside
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside); // Clean up event listener
    };
  }, [isOpen]);

  return (
    <div
      ref={accordionRef}
      className={`accordion ${
        isOpen ? "accordion-opened" : ""
      } ${accordionClass} ${clickCount && isOpen ? `zIndexPopup${clickCount}` : ""}`}
    >
      <div
        className={`accordion-header ${isOpen ? "open" : ""}`}
        onClick={toggleAccordion}
      >
        <div className="accordion-title">{title}</div>
        <div className="accordion-icon">
          <FontAwesomeIcon icon={isOpen ? faXmark : faChevronDown} />
        </div>
      </div>
      {isOpen && <div className="accordion-text">{text}</div>}
    </div>
  );
};

export default AccordionComponent;