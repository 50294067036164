import { Helmet } from "react-helmet-async";
import shipGif from "../../assets/images/landing_page_ship.gif";
import logo from "../../assets/icons/logo.png";
import { useState } from "react";
import "../../styles/landing.css";
import { useNavigationContext } from "../../context/NavigationContext";

const LandingPage = () => {
  const [slideOut, setSlideOut] = useState(false);
  const { setIsOpen } = useNavigationContext();

  const handleClick = () => {
    setSlideOut(true);
  };

  const handleAnimationEnd = () => {
    setIsOpen(true);
    window.location.href = "/services";
  };

  return (
    <div className="main-content landing">
      <Helmet>
        <title>Iris Web - L'agence Web Créative !</title>
        <meta
          name="description"
          content="Tu cherches à propulser ton entreprise et gagner en visibilité ? Laisse-toi guider et embarque avec nous vers d'autres horizons !"
        />
      </Helmet>
      <div className="landing-content-container">
        <img src={logo} className="logo-landing" alt="Logo IRIS WEB" />
        <img
          src={shipGif}
          className={`img-landing ${slideOut ? "slide-out" : ""}`}
          onAnimationEnd={slideOut ? handleAnimationEnd : null}
          alt="Gif de vaisseau"
        />
        <div
          className={`landing-content ${slideOut ? "slide-out-content" : ""}`}
        >
          <h1 className="color-lilac">
            Prends ton billet vers la créativité !
          </h1>
          <h2 className="color-lilac">
            Tu cherches à propulser ton entreprise et gagner en visibilité ?
          </h2>
        </div>
        <button
          onClick={handleClick}
          className={`landing-button ${slideOut ? "slide-out-content" : ""}`}
        >
          C'est parti !
        </button>
      </div>
    </div>
  );
};

export default LandingPage;
