import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { PopupProvider } from "./context/PopupContext";
import { HelmetProvider } from "react-helmet-async";
import { NavigationProvider } from "./context/NavigationContext";
import { AccordionProvider } from "./context/AccordionContext";
import ParticlesComponent from "./components/ParticlesBackground";

ReactDOM.render(
  <HelmetProvider>
    <NavigationProvider>
      <PopupProvider>
        <AccordionProvider>
          <BrowserRouter>
          <ParticlesComponent id="particles" />
            <App />
          </BrowserRouter>
        </AccordionProvider>
      </PopupProvider>
    </NavigationProvider>
  </HelmetProvider>,
  document.getElementById("root")
);
