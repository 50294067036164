import React from "react";
import Slider from "react-slick";
import "../../styles/aboutus.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselAboutUsItemComponent from "./CarouselAboutUsItem";
import chloeAvatar from "../../assets/images/team/chloe.gif";
import phoebeAvatar from "../../assets/images/team/phoebe.gif";
import lucasAvatar from "../../assets/images/team/lucas.gif";
import marinaAvatar from "../../assets/images/team/marina.gif";
import yassineAvatar from "../../assets/images/team/yassine.gif";

const CarouselAboutUsComponent = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    focusOnSelect: true,
    centerMode: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const employeesData = [
    {
      name: "Chloé",
      avatar: chloeAvatar,
      job: "RESPONSABLE DE COMMUNICATION / MARKETING",
      jobDescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      name: "Phoebe",
      avatar: phoebeAvatar,
      job: "GRAPHISTE / WEBDESIGNER",
      jobDescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      name: "Lucas",
      avatar: lucasAvatar,
      job: "DÉVELOPPEUR WEB",
      jobDescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      name: "Marina",
      avatar: marinaAvatar,
      job: "CHARGÉE DE COMMUNICATION ET D'ÉVENEMENTIEL",
      jobDescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      name: "Yassine",
      avatar: yassineAvatar,
      job: "DESIGNER GRAPHIQUE",
      jobDescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  ];
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div className="company-presentation">
          <div className="company-presentation-header"></div>
          <div className="company-presentation-body">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </div>
        </div>
        {employeesData.map((employee) => (
          <CarouselAboutUsItemComponent
            key={employee.name} // Assurez-vous d'avoir une clé unique pour chaque composant
            name={employee.name}
            avatar={employee.avatar}
            job={employee.job}
            jobDescription={employee.jobDescription}
          />
        ))}
      </Slider>
    </div>
  );
};
export default CarouselAboutUsComponent;
