import Slider from "react-slick";
import rond from "../../assets/icons/rond_service.png";
import placeholder from "../../assets/images/portfolio/placeholder.png";
import { useState } from "react";
import ResponsiveModalComponent from "../ResponsiveModal";

/* PORTFOLIO IMAGES */
// Avenir Investissements
import avenirInvestissementsMockupApron from "../../assets/images/portfolio/Avenir_investissements/mockup_apron.jpg";
import avenirInvestissementsMockupBag from "../../assets/images/portfolio/Avenir_investissements/mockup_bag.jpg";
import avenirInvestissementsMockupBagGrocer from "../../assets/images/portfolio/Avenir_investissements/mockup_bag_grocer.jpg";
import avenirInvestissementsMockupBanner from "../../assets/images/portfolio/Avenir_investissements/mockup_banner.jpg";
import avenirInvestissementsMockupFlyer from "../../assets/images/portfolio/Avenir_investissements/mockup_flyer.jpg";
import avenirInvestissementsMockupOutside from "../../assets/images/portfolio/Avenir_investissements/mockup_outside.jpg";
import avenirInvestissementsMockupPins from "../../assets/images/portfolio/Avenir_investissements/mockup_pins.jpg";
import avenirInvestissementsMockupPoster from "../../assets/images/portfolio/Avenir_investissements/mockup_poster.jpg";
import avenirInvestissementsMockupSign from "../../assets/images/portfolio/Avenir_investissements/mockup_sign.jpg";
import avenirInvestissementsMockupSign2 from "../../assets/images/portfolio/Avenir_investissements/mockup_sign2.jpg";
import avenirInvestissementsMockupWall from "../../assets/images/portfolio/Avenir_investissements/mockup_wall.jpg";
// IRIS IT
import irisItMockupCarteVoeux from "../../assets/images/portfolio/IRIS_IT/mockup_carte_voeux.jpg";
import irisItMockupFlyer from "../../assets/images/portfolio/IRIS_IT/mockup_flyer.jpg";
import irisItMockupKakemono from "../../assets/images/portfolio/IRIS_IT/mockup_kakemono.jpg";
import irisItMockupPlaquetteMetiers from "../../assets/images/portfolio/IRIS_IT/mockup_plaquette_metiers.jpg";
import irisItMockupSocialMedia from "../../assets/images/portfolio/IRIS_IT/mockup_social_media.jpg";
// Mobile Angelo
import mobileAngeloMockupBanner from "../../assets/images/portfolio/Mobile_Angelo/mockup_banner.jpg";
import mobileAngeloMockupInstaPost from "../../assets/images/portfolio/Mobile_Angelo/mockup_insta_post.jpg";
import mobileAngeloMockupTelephone from "../../assets/images/portfolio/Mobile_Angelo/mockup_telephone.jpg";
import mobileAngeloMockupSite from "../../assets/images/portfolio/Mobile_Angelo/mockup_site.jpg";
// NH Accompagnements
import nhAccompagnementsMockupInstaPost from "../../assets/images/portfolio/NH_Accompagnements/mockup_insta_post.jpg";
import nhAccompagnementsMockupSite from "../../assets/images/portfolio/NH_Accompagnements/mockup_site.jpg";
import nhAccompagnementsMockupTablette from "../../assets/images/portfolio/NH_Accompagnements/mockup_tablette.jpg";
import nhAccompagnementsMockupTelephone from "../../assets/images/portfolio/NH_Accompagnements/mockup_telephone.jpg";
// Pépites Locales
import pepitesLocalesMockupBox from "../../assets/images/portfolio/Pepites_locales/mockup_box.png";
import pepitesLocalesMockupBox2 from "../../assets/images/portfolio/Pepites_locales/mockup_box2.png";
import pepitesLocalesMockupTelephone from "../../assets/images/portfolio/Pepites_locales/mockup_telephone.jpg";
import pepitesLocalesMockupSite from "../../assets/images/portfolio/Pepites_locales/mockup_site.jpg";
import pepitesLocalesMockupTshirt from "../../assets/images/portfolio/Pepites_locales/mockup_tshirt.png";
import pepitesLocalesPageAbonnement from "../../assets/images/portfolio/Pepites_locales/page_abonnement.png";
import pepitesLocalesPageAccueil from "../../assets/images/portfolio/Pepites_locales/page_accueil.png";
import pepitesLocalesPageNosCoffrets from "../../assets/images/portfolio/Pepites_locales/page_nos_coffrets.png";
import pepitesLocalesPanier from "../../assets/images/portfolio/Pepites_locales/page_panier.png";
// SUEZ
import suezMockupBanner from "../../assets/images/portfolio/SUEZ/mockup_banner.jpg";
import suezMockupPosters1 from "../../assets/images/portfolio/SUEZ/mockup_posters1.png";
import suezMockupPosters2 from "../../assets/images/portfolio/SUEZ/mockup_posters2.jpg";
import suezPoster1 from "../../assets/images/portfolio/SUEZ/poster1.png";
import suezPoster2 from "../../assets/images/portfolio/SUEZ/poster2.png";

const CarouselPortfolioResponsive = () => {
  const [openPopupIndex, setOpenPopupIndex] = useState(null);

  const popupsSuez = [
    {
      title: "Opération SUEZ",
      text: "<p>SUEZ nous a sollicité pour la création d'une campagne d'affiche pour promouvoir les règles de sécurité sur le lieu de travail.</p>",
      imagesList: {
        suezMockupBanner,
        suezMockupPosters1,
        suezMockupPosters2,
        suezPoster1,
        suezPoster2,
      },
      subText:
        "<hr /><h3>Rapport de mission</h3><p>Une campagne ayant pour projet de prôner l'humour et l'impact graphique afin de marquer le message dans l'esprit du lecteur.</p>",
    },
  ];

  const popupsAvenirInvestissements = [
    {
      title: "Expédition Avenir Investissements",
      text: "<p>Avenir Investissements a proposé un appel d'offre pour la stratégie et l'identité graphique de leur prochaine Halle situé à Lille.</p>",
      imagesList: {
        avenirInvestissementsMockupApron,
        avenirInvestissementsMockupBag,
        avenirInvestissementsMockupBagGrocer,
        avenirInvestissementsMockupBanner,
        avenirInvestissementsMockupFlyer,
        avenirInvestissementsMockupOutside,
        avenirInvestissementsMockupPins,
        avenirInvestissementsMockupPoster,
        avenirInvestissementsMockupSign,
        avenirInvestissementsMockupSign2,
        avenirInvestissementsMockupWall,
      },
      subText:
        "<hr /><h3>Rapport de mission</h3><p>Projet graphique décalé de la conccurence.<br />Impact graphique visant à apport une identité forte sur leurs supports !</p>",
    },
  ];

  const popupsPepitesLocales = [
    {
      title: "Exploration Pépites Locales",
      text: "<p>Projet de coffrets de dégustation autour de produits locaux.<br />La mission est de lancer le projet en partant de sa création.</p>",
      imagesList: {
        pepitesLocalesMockupBox,
        pepitesLocalesMockupBox2,
        pepitesLocalesMockupTelephone,
        pepitesLocalesMockupSite,
        pepitesLocalesMockupTshirt,
        pepitesLocalesPageAbonnement,
        pepitesLocalesPageAccueil,
        pepitesLocalesPageNosCoffrets,
        pepitesLocalesPanier,
      },
      subText:
        "<hr /><h3>Rapport de mission</h3><p>Nous avons développé le projet dans son intégralité et accompagné son créateur jusqu'à son lancement !<br />Un projet qui continue sans notre intervention après une bonne formation !</p>",
    },
  ];

  const popupsNHAcompagnements = [
    {
      title: "Épopée NH Accompagnements",
      text: "<p>NH Accompagnements s'est rapproché de nous pour la création d'un site web dans le domaine du coaching professionnel !</p>",
      imagesList: {
        nhAccompagnementsMockupInstaPost,
        nhAccompagnementsMockupSite,
        nhAccompagnementsMockupTablette,
        nhAccompagnementsMockupTelephone,
      },
      subText:
        "<hr /><h3>Rapport de mission</h3><p>Le site se voit aujourd'hui fluctuant et davantage attrayant, permettant à Nicole Hardy de continuer d'évoler sans notre intervention !</p>",
    },
  ];

  const popupsIrisIT = [
    {
      title: "Voyage IRIS IT",
      text: "<p>Directement affilié à IRIS IT, nous sommes sollicités pour la communication sous tous aspects.</p>",
      imagesList: {
        irisItMockupCarteVoeux,
        irisItMockupFlyer,
        irisItMockupKakemono,
        irisItMockupPlaquetteMetiers,
        irisItMockupSocialMedia,
      },
      subText:
        "<hr /><h3>Rapport de mission</h3><p>Des projets constants et variés qui nous poussent à évoluer dans tous nos domaines d'activités !</p>",
    },
  ];

  const popupsMobileAngelo = [
    {
      title: "Aventure Mobile Angelo",
      text: "<p>Mobile Angelo nous a sollicité pour la création de leur site web et la gestion de leurs réseaux sociaux !</p>",
      imagesList: {
        mobileAngeloMockupBanner,
        mobileAngeloMockupInstaPost,
        mobileAngeloMockupTelephone,
        mobileAngeloMockupSite,
      },
      subText:
        "<hr /><h3>Rapport de mission</h3><p>Après la création d'un site web opérationnel, nous sommes encore aujourd'hui chargé de communiquer de leurs actualités !</p>",
    },
  ];

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    focusOnSelect: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleModalOpen = (index) => {
    setOpenPopupIndex(index);
    if (openPopupIndex === index) {
      setOpenPopupIndex(null);
    }
    const mainContent = document.querySelector(".main-content");
    mainContent.classList.add("hide");
  };

  return (
    <div className="carousel-portfolio">
      <Slider {...settings}>
        <div className="carousel-portfolio-modal">
          <ResponsiveModalComponent
            modals={popupsSuez}
            buttonImage={rond}
            buttonText="Sécurité SUEZ"
            modalPage="portfolio"
            image={suezPoster2}
            subTitle="Passer un message !"
            description="Un projet de communication print autour de la sécurité du travail jouant sur l'humour !"
            modalIsOpen={openPopupIndex === 0}
            onOpen={() => handleModalOpen(0)}
          />
        </div>
        <div className="carousel-portfolio-modal">
          <ResponsiveModalComponent
            modals={popupsAvenirInvestissements}
            buttonImage={rond}
            buttonText="Halle Par-ci Par-Là"
            modalPage="portfolio"
            image={avenirInvestissementsMockupFlyer}
            subTitle="Un lancement frappant !"
            description="Un projet de Halle commerçante où notre stratégie s'est fixée sur cette volonté d'être décalé et donc d'attirer !"
            modalIsOpen={openPopupIndex === 1}
            onOpen={() => handleModalOpen(1)}
          />
        </div>
        <div className="carousel-portfolio-modal">
          <ResponsiveModalComponent
            modals={popupsPepitesLocales}
            buttonImage={rond}
            buttonText="Pépites Locales"
            modalPage="portfolio"
            image={pepitesLocalesMockupBox}
            subTitle="Des valeurs et des saveurs !"
            description="Un projet e-commerce prônant des valeurs fortes et transportant des saveurs à chaque coffret !"
            modalIsOpen={openPopupIndex === 2}
            onOpen={() => handleModalOpen(2)}
          />
        </div>
        <div className="carousel-portfolio-modal">
          <ResponsiveModalComponent
            modals={popupsNHAcompagnements}
            buttonImage={rond}
            buttonText="NH Accompagnements"
            modalPage="portfolio"
            image={nhAccompagnementsMockupSite}
            subTitle="Accompagner et évoluer !"
            description="Un lifting de l'identité graphique d'une firme de coach professionnel afin d'améliorer l'image de marque."
            modalIsOpen={openPopupIndex === 3}
            onOpen={() => handleModalOpen(3)}
          />
        </div>
        <div className="carousel-portfolio-modal">
          <ResponsiveModalComponent
            modals={popupsIrisIT}
            buttonImage={rond}
            buttonText="IRIS IT"
            modalPage="portfolio"
            image={irisItMockupPlaquetteMetiers}
            subTitle="Transmettre l'information !"
            description="Sur la base d'une charte déjà établie, nous développons une stratégie pour leur communication !"
            modalIsOpen={openPopupIndex === 4}
            onOpen={() => handleModalOpen(4)}
          />
        </div>
        <div className="carousel-portfolio-modal">
          <ResponsiveModalComponent
            modals={popupsMobileAngelo}
            buttonImage={rond}
            buttonText="Mobile Angelo"
            modalPage="portfolio"
            image={mobileAngeloMockupSite}
            subTitle="Gardien de leur intégrité !"
            description="Nous sommes chargés d'assurer la fiabilité de leur site, un suivi constant afin de maintenir son bon fonctionnement !"
            modalIsOpen={openPopupIndex === 5}
            onOpen={() => handleModalOpen(5)}
          />
        </div>
        {/* <div className="carousel-portfolio-modal">
          <ResponsiveModalComponent
            modals={popupsIVS}
            buttonImage={rond}
            buttonText="IVS"
            modalPage="portfolio"
            modalIsOpen={openPopupIndex === 6}
            onOpen={() => handleModalOpen(6)}
          />
        </div> */}
      </Slider>
    </div>
  );
};

export default CarouselPortfolioResponsive;
