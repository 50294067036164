import { createContext, useState, useContext, useEffect } from "react";

const NavigationContext = createContext();

export const useNavigationContext = () => useContext(NavigationContext);

export const NavigationProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState();

  useEffect(() => {
    const referrer = document.referrer;
    const landingPageUrl = "https://iris-web-creation.fr/";

    if (referrer === landingPageUrl) {
      setIsOpen(true);
    }
  }, []);

  return (
    <NavigationContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </NavigationContext.Provider>
  );
};
